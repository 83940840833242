<template>
  <ManageManufacturer dialog-name="Manufacturers" />
</template>
<script>
import ManageManufacturer from '@/views/app/manage/Manufacturer'

export default {
  name: 'ManufacturerWrapper',
  components: {
    ManageManufacturer
  }
}
</script>
